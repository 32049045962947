<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="650px">
      <v-card>
        <v-card-title>{{ $t("resendmail.header") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:500px">
          <v-layout row wrap class="pa-4">
            <v-flex xs12 lg12>
              <div class="text-center">
              <img center src="@/assets/email.png" width="30%" height="30%" />
              </div>
              <br/>
              <h2 class="text-center">{{ $t("resendmail.wordresend") }}</h2>
              
              <!-- <h4 class="text-center">ไม่พบประวัติการส่งอีเมลสำหรับเอกสารที่เลือก</h4> -->

              <br />
              <v-layout class="pl-4 pr-4" v-for="item in formemail" :key="item.id"
                ><v-flex xs12 lg11 class="pr-3"
                  ><v-text-field
                    v-model="item.email_name"
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                    label="Email"
                    dense
                    :rules="requiredemail_biz"
                  ></v-text-field>
                  </v-flex
                ><v-flex xs12 lg1 class="pt-1"
                  ><v-btn class="elevation-0" dark fab x-small color="red" @click="deleteformmail(item.id)"><v-icon>mdi-minus</v-icon></v-btn></v-flex
                ></v-layout
              >
              <v-layout><span style="color:red; font-size: 15px; font-weight: bold;">{{ $t("resendmail.note") }}</span></v-layout>
              <v-layout
                ><v-spacer></v-spacer><v-btn text @click="addemail()"><v-icon color="green">mdi-plus</v-icon><span style="color:green;">{{ $t("resendmail.addmail") }}</span></v-btn></v-layout
              >
              <h3 class="text-center">{{ $t("resendmail.wordresend_2") }}</h3>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closedialog()">{{ $t("admin.close") }}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_resendmail()">{{ $t("resendmail.confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["show", "filedata"],
    validations: {
    receiver: { required, email }
  },
  data: function() {
    return {
      email_name: "",
      formemail:[
        {
         email_name:"",
         id:0,
        },
      ],
      requiredemail_biz: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => this.fn_checkemail(value) || "รูปแบบอีเมลไม่ถูกต้อง"],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          //   if(this.test === 1){
           console.log(this.filedata);
          //   this.loadstaff();
          //   this.checktap();
          //   }
          //   this.test=0;
          console.log("opensetpermission");
        }

        // this.$emit('closeDrag');
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
        receiverError(item) {
      const errors = [];
      if (!this.$v.item.$dirty) return errors;
      !this.$v.item.required && errors.push(this.$t('sendtoemail.pleasefillemail'));
      !this.$v.item.email && errors.push(this.$t('sendtoemail.invalidemailformat'));
      return errors;
    }
  },
  //     watch: {
  //     show: function(val) {
  //       if (val === true) {

  //       } else {

  //       }
  //     },
  //   },
  methods: {
        fn_checkemail(value) {
          //const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         const pattern = /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}|[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}[,][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    closedialog() {
      this.email_name="",
      this.formemail=[
        {
         email_name:"",
         id:0,
        },
      ],
      this.$emit("closedialog");
    },
     //กดเพิ่มช่องใส่ email
    addemail() {
      this.formemail.push({email_name:"", id:this.formemail[this.formemail.length-1]["id"]+1})
      console.log(this.formemail);
    },
    //กดลบช่องใส่ email
    deleteformmail(_id){
      if(this.formemail.length > 1){
       this.formemail.splice(_id, 1)
        console.log(this.formemail); 
      }
        
    },
    async fn_resendmail() {      
      const check_email = ดthis.formemail.map(({email_name}) => this.fn_checkemail(email_name))
      if(check_email.includes(false)){
        Toast.fire({
                icon: "error",
                title: "กรุณากรอก email ให้ถูกต้อง",
              });
      }else{
       const inbox_id = this.formemail.map(({email_name}) => email_name)
       let inbox_idTostring = inbox_id.toString();
      
      var payload = {
        inbox_id: [this.filedata["inbox_id"]],
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        mail_receiver: inbox_idTostring,
        user_id: this.dataUsername,
        send_from: "outbox",
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("payload",payload);
      await this.axios
      .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/send_inbox_to_mail",
            payload,{headers: { Authorization: auth.code },}
      )
      .then((response) =>{
         console.log(response);
        if(response.data.status === 'OK'){
            this.email_name = "",
      this.formemail = [{email_name:"",id:0}],
             Toast.fire({
                icon: "success",
                title: "ส่ง E-mail สำเร็จ",
              });
              this.closedialog()

        }else{
          
          Toast.fire({
                icon: "error",
                title: response.errorMassage,
          });
        }
      }).catch(error => {
        Toast.fire({
                icon: "error",
                title: error,
          });
      });
      }
    },
  },
};
</script>
